
import { commitAddNotification } from '@/store/main/mutations';
import { computed, defineComponent, onMounted, ref, watch, Ref } from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { DynamicList } from '@/interfaces/api/general/dynamicList';
import { FormItem, Status } from '@/interfaces/lib/model';
import { Sds, SdsDynamicListResult } from '@/interfaces/model/general/sds';
import { Department } from '@/interfaces/model/general/department';
import { FilterCriteria } from '@/lib/filterCriteria';
import { MAX_LIMIT } from '@/lib/constant';
import { StatusId } from '@/lib/enum';
import { pdfDisplayOptions, statusList } from '@/lib/formItem';
import { removeLastPdfExtension } from '@/lib/formatter';
import { casNumberPattern } from '@/lib/regex';
import router from '@/router';
import { store } from '@/store/index';
import { readDepartments } from '@/store/main/getters';
import {
  dispatchCancelCreateSimpleExportRequest,
  dispatchCreateReanalysisSds,
  dispatchDeleteDynamicListResult,
  dispatchGetDynamicLists,
  dispatchGetExportCreateSimple,
  dispatchGetExportCreateSimpleV3,
  dispatchPostDynamicListResult,
  dispatchPutDynamicListResult,
  dispatchUpdateCompanySdses,
} from '@/store/main/actionsMain';
import SdsBottomSheet from '@/views/components/views/dashboard/bottomSheet/SdsBottomSheet.vue';
import CompanySdsTable from '@/views/components/views/dashboard/CompanySdsTable.vue';
import DownloadProgress from '@/views/components/views/dashboard/DownloadProgress.vue';
import SdsSearchForm from '@/views/components/views/dashboard/SdsSearchForm.vue';
import SdsTableDrawer from '@/views/components/views/dashboard/SdsTableDrawer.vue';
import {
  useSdsComposable,
  SortState,
} from '@/views/composables/main/general/useSds';
import ExportSdsDialog from '@/views/components/views/dashboard/ExportSdsDialog.vue';
import { TagResponse } from '@/interfaces/model/general/tag';
import { UpdateCompanySdsesRequestBody } from '@/interfaces/api/general/company';
import { useTagComposable } from '@/views/composables/main/general/useTag';
import { useSelectedItemsComposable } from '@/views/composables/main/general/useSelectedItems';
import { useDepartmentComposable } from '@/views/composables/main/general/useDepartment';
import { usePermissionComposable } from '@/views/composables/main/general/usePermission';
import { NewSds } from '@/lib/constructor/model/general/sds';

export default defineComponent({
  components: {
    SdsBottomSheet,
    CompanySdsTable,
    SdsTableDrawer,
    SdsSearchForm,
    DownloadProgress,
    ExportSdsDialog,
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing) {
      const isAllowed: boolean = window.confirm(
        '編集中の作業は保存されませんが、よろしいですか？'
      );
      if (isAllowed) {
        next();
      }
    } else {
      next();
    }
  },
  props: {
    isExporting: Boolean,
  },
  setup() {
    const showSdsDetailView = ref<boolean>(false);
    const pdfName = ref<string>('');
    const keywordList = ref<Array<string>>([]);
    const productFormItems = ref<Array<{ text: string; value: number }>>([]);
    const materialFormItems = ref<Array<FormItem>>([]);
    const casFormItems = ref<Array<FormItem>>([]);
    const page = ref<number>(1);
    const limit = ref<number>(50);
    const isLoadingSdses = ref<boolean>(false);
    const isLoadingSds = ref<boolean>(false);
    const isEditing = ref<boolean>(false);
    const allStatusIdList = StatusId.values();
    const canExportCreateSimpleCount = ref<number>(0);
    const canExportCreateSimpleCountV3 = ref<number>(0);
    const dialogShow = ref<boolean>(false);
    const reanalysisSdsModalShow = ref<boolean>(false);
    const isChange = ref<number>(0);
    const isOpenExportDialog = ref(false);

    const searchUserText = ref<string>('');
    const searchTagText = ref<string>('');
    const searchDepartmentText = ref<string>('');

    const isDialogVisibleRevisedAtStartDate = ref(false);
    const isDialogVisibleRevisedAtEndDate = ref(false);
    const isDialogVisibleCreatedAtStartDate = ref(false);
    const isDialogVisibleCreatedAtEndDate = ref(false);

    const saveDate = (state) => {
      if (state == 1) {
        isDialogVisibleRevisedAtStartDate.value = false;
      }
      if (state == 2) {
        isDialogVisibleRevisedAtEndDate.value = false;
      }
      if (state == 3) {
        isDialogVisibleCreatedAtStartDate.value = false;
      }
      if (state == 4) {
        isDialogVisibleCreatedAtEndDate.value = false;
      }
    };

    const openExportDialog = (): void => {
      isOpenExportDialog.value = true;
    };
    const closeExportDialog = (): void => {
      isOpenExportDialog.value = false;
    };

    const reanalysisSdsComment = ref<{
      comment: string;
      sds_id: string;
    }>({
      comment: '',
      sds_id: '',
    });
    const dynamicLists = ref<Array<DynamicList>>([]);
    const activeSds = ref<Sds>(NewSds);
    const selectableStatusList: Array<StatusId> = StatusId.getDropdownOptions();
    const statusTextList: Array<Status> = Array.from(
      new Set(
        selectableStatusList.map((status) => ({
          value: status.value,
          groupId: status.groupId,
          state: StatusId.getStatusFromStatusId(status.value),
        }))
      )
    );

    const isTagSelected = (item) => {
      return filterCriteria.value.tags.some((tag) => tag.id === item.id);
    };

    const isUserSelected = (item) => {
      return filterCriteria.value.uploadUsers.some(
        (user) => user === item.uuid
      );
    };

    const departments = computed(() => readDepartments(store));
    const {
      sds,
      sdses,
      total,
      pdfBlob,
      companyUploadUsers,
      fetchCompanyUploadUsers,
      fetchCompanySds,
      fetchCompanySdses,
      fetchSdsPdf,
      fetchCompanySdsesCount,
      fetchCompanySdsesCountWithCondition,
      updateCompanySds,
      deleteCompanySdses,
    } = useSdsComposable();
    const { getCompanyDepartments, companyDepartments } =
      useDepartmentComposable();
    const [
      selectedSdses,
      isSelectedAllSdses,
      isSelectedIndeterminateSdses,
      toggleSelectedSds,
      toggleAllSelectedSdses,
      resetSelectedSdses,
    ] = useSelectedItemsComposable<Sds>(
      sdses.value,
      (sds1, sds2) => sds1.uuid === sds2.uuid
    );
    const { hasPermission, PERMISSIONS: P } = usePermissionComposable();
    const { tags, getCompanyTag } = useTagComposable();

    const sortState = ref<SortState>({
      name: 'none',
      revised_at: 'none',
      created_at: 'none',
    });

    const getFullName = (userId: string): string => {
      const user = companyUploadUsers.value.find(
        (user) => user.uuid === userId
      );
      return user
        ? `${user.last_name || 'Unknown'} ${user.first_name || ''}`
        : '';
    };

    const onClickProductName = (name: string): void => {
      filterCriteria.value.productName = name;
      applyFilters();
    };

    const toggleSort = (column: string): void => {
      if (sortState.value[column] === 'none') {
        sortState.value[column] = 'asc';
      } else if (sortState.value[column] === 'asc') {
        sortState.value[column] = 'desc';
      } else {
        sortState.value[column] = 'none';
      }
      Object.keys(sortState.value).forEach((key) => {
        if (key !== column) {
          sortState.value[key] = 'none';
        }
      });

      applyFilters();
    };

    const cancelExportCreateSimple = (): void => {
      dispatchCancelCreateSimpleExportRequest(store);
      dialogShow.value = false;
    };

    const openReanalysisSdsModal = (sdsId: string): void => {
      reanalysisSdsModalShow.value = true;
      reanalysisSdsComment.value = {
        comment: '',
        sds_id: sdsId,
      };
    };

    const closeReanalysisSdsModal = (): void => {
      reanalysisSdsModalShow.value = false;
    };

    const reanalysisSds = async (): Promise<void> => {
      const payload = {
        sdsId: reanalysisSdsComment.value.sds_id,
        comment: reanalysisSdsComment.value.comment,
      };
      await dispatchCreateReanalysisSds(store, payload);
      closeReanalysisSdsModal();

      await doFetchCompanySdses();
      await doFetchCompanySdsesCount();
      await doFetchCompanySdsesCountWithCondition();

      await doFetchCompanySds(reanalysisSdsComment.value.sds_id);
    };

    watch(showSdsDetailView, (next) => {
      if (next === false) {
        activeSds.value = NewSds;
      }
    });

    const currentIndex = ref(0);
    const hasPrevSds = computed((): boolean => {
      return currentIndex.value > 0;
    });

    const hasNextSds = computed((): boolean => {
      return currentIndex.value < sdses.value.length - 1;
    });

    const showPrevSds = async (): Promise<void> => {
      const index = currentIndex.value - 1;

      showSdsDetail(sdses.value[index], index);
    };

    const showNextSds = async (): Promise<void> => {
      const index = currentIndex.value + 1;

      showSdsDetail(sdses.value[index], index);
    };

    const resetPagenation = (): void => {
      page.value = 1;
    };

    const showSdsDetail = async (item: Sds, index: number): Promise<void> => {
      await doFetchCompanySds(item.uuid);
      await doFetchPdf(item.uuid);

      showSdsDetailView.value = true;
      activeSds.value = sds.value;
      isLoadingSds.value = true;

      canExportCreateSimpleCount.value = 0;
      canExportCreateSimpleCountV3.value = 0;

      currentIndex.value = index;

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...item.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );
      materialFormItems.value.splice(0);
      casFormItems.value.splice(0);
      for (const product of sds.value.sds_products) {
        // material_list内のcas_listをcasNumberPatternでマッチングし、1つ以上マッチしたらcanExportCreateSimpleCountV3をインクリメント
        const casList = product.sds_section3.material_list
          .map((x) => x.cas_list)
          .flat()
          .filter((x) => x.cas_number.match(casNumberPattern));
        if (casList.length > 0) {
          canExportCreateSimpleCountV3.value++;
        }

        for (const material of product.sds_section3.material_list) {
          materialFormItems.value.push({
            text: material.material_name,
            value: material.material_name,
          });
          for (const cas of material.cas_list) {
            casFormItems.value.push({
              text: cas.cas_number,
              value: cas.cas_number,
            });
            if (cas.cas_number.match(casNumberPattern)) {
              canExportCreateSimpleCount.value++;
            }
          }
        }
      }

      isLoadingSds.value = false;
    };

    const doFetchPdf = async (sdsId: string): Promise<void> => {
      fetchSdsPdf({
        companyId: store.state.main.company.uuid,
        sdsId: sdsId,
      });
    };

    const doFetchCompanySds = async (sdsId: string): Promise<void> => {
      await fetchCompanySds({
        sdsId: sdsId,
        companyId: store.state.main.company.uuid,
      });
    };

    const doFetchCompanyUploadUsers = async (): Promise<void> => {
      //fetch users from the same company with the current user
      await fetchCompanyUploadUsers({
        companyId: store.state.main.company.uuid,
      });
    };

    const doFetchCompanySdses = async (): Promise<void> => {
      isLoadingSdses.value = true;

      await fetchCompanySdses({
        limit: limit.value,
        page: page.value - 1,
        keywordList: keywordList.value,
        companyId: store.state.main.company.uuid,
        statusIdList: filterCriteria.value.statusGroupId.length
          ? selectableStatusList
              .filter((statusId) =>
                filterCriteria.value.statusGroupId.includes(statusId.groupId)
              )
              .map((statusId) => statusId.value)
          : allStatusIdList,
        sortState: sortState.value,
        userIdList: filterCriteria.value.uploadUsers.length
          ? filterCriteria.value.uploadUsers
          : [],
        tagIdList: filterCriteria.value.tags.length
          ? filterCriteria.value.tags.map((tag) => tag.id)
          : [],
        departmentIdList: filterCriteria.value.departments.map((d) => d.uuid),
        sdsName: filterCriteria.value.sdsName,
        productName: filterCriteria.value.productName,
        revisedAtStartDate: filterCriteria.value.revisedAtStartDate,
        revisedAtEndDate: filterCriteria.value.revisedAtEndDate,
        createdAtStartDate: filterCriteria.value.createdAtStartDate,
        createdAtEndDate: filterCriteria.value.createdAtEndDate,
        hasProduct: true,
        hasSection1: false,
        hasSection2: false,
        hasSection3: true,
        hasSection8: false,
        hasSection8Acgih: false,
        hasSection8Jsoh: false,
        hasSection8DnelDmel: false,
        hasSection9: false,
        hasSection14: true,
        hasSection15: false,
        hasAssistantLogCannotAnalysis: false,
      });

      isLoadingSdses.value = false;
    };

    const doFetchCompanySdsesCountWithCondition = async (): Promise<void> => {
      isLoadingSdses.value = true;

      await fetchCompanySdsesCountWithCondition({
        companyId: store.state.main.company.uuid,
        keywordList: keywordList.value,
        sdsName: filterCriteria.value.sdsName,
        productName: filterCriteria.value.productName,
        revisedAtStartDate: filterCriteria.value.revisedAtStartDate,
        revisedAtEndDate: filterCriteria.value.revisedAtEndDate,
        createdAtStartDate: filterCriteria.value.createdAtStartDate,
        createdAtEndDate: filterCriteria.value.createdAtEndDate,
        statusIdList: filterCriteria.value.statusGroupId.length
          ? selectableStatusList
              .filter((statusId) =>
                filterCriteria.value.statusGroupId.includes(statusId.groupId)
              )
              .map((statusId) => statusId.value)
          : allStatusIdList,
        userIdList: filterCriteria.value.uploadUsers.length
          ? filterCriteria.value.uploadUsers
          : [],
        departmentIdList: filterCriteria.value.departments.map((d) => d.uuid),
        tagIdList: filterCriteria.value.tags.length
          ? filterCriteria.value.tags.map((tag) => tag.id)
          : [],
      });

      isLoadingSdses.value = false;
    };

    const doFetchCompanySdsesCount = async (): Promise<void> => {
      isLoadingSdses.value = true;

      await fetchCompanySdsesCount({
        companyId: store.state.main.company.uuid,
        keywordList: keywordList.value,
      });

      isLoadingSdses.value = false;
    };

    const doDeleteCompanySdses = async (): Promise<void> => {
      await deleteCompanySdses({
        companyId: store.state.main.company.uuid,
        form: {
          sds_uuid_list: selectedSdses.value.map((sds) => sds.uuid),
        },
      });
      await doFetchCompanySdses();
      await doFetchCompanySdsesCount();
      await doFetchCompanySdsesCountWithCondition();
    };

    const doUpdateCompanySds = async (
      sdsId: string,
      data: Sds,
      tags: TagResponse[],
      departments: Department[]
    ): Promise<void> => {
      await updateCompanySds(
        store.state.main.company.uuid,
        sdsId,
        data,
        departments,
        tags,
        store.state.main.userProfile
      );
      await doFetchCompanySds(data.uuid);
      await doFetchCompanySdses();
      await doFetchCompanySdsesCount();
      await doFetchCompanySdsesCountWithCondition();
    };

    const fetchDynamicLists = async () => {
      dynamicLists.value.splice(0);

      const res = await dispatchGetDynamicLists(store, {
        page: page.value - 1,
        limit: MAX_LIMIT,
        showAll: false,
      });
      if (res && res.data) {
        dynamicLists.value = [...res.data.items];
      }
    };

    const downloadExportData = async (): Promise<void> => {
      const params = new URLSearchParams(location.href.split('?')[1]);
      const isExport = params.get('export');

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!isExport) {
        const link = document.createElement('a');
        const bucket = params.get('bucket');
        const companyId = params.get('companyId');
        const filename = params.get('filename'); // 「タイムスタンプ値.SDS一覧.拡張子」
        const query = {
          'X-Goog-Algorithm': params.get('X-Goog-Algorithm') ?? '',
          'X-Goog-Credential': params.get('X-Goog-Credential') ?? '',
          'X-Goog-Date': params.get('X-Goog-Date') ?? '',
          'X-Goog-Expires': params.get('X-Goog-Expires') ?? '',
          'X-Goog-SignedHeaders': params.get('X-Goog-SignedHeaders') ?? '',
          'X-Goog-Signature': params.get('X-Goog-Signature') ?? '',
        };
        const urlSearchParam = new URLSearchParams(query).toString();
        const url = `https://storage.googleapis.com/${bucket}/export/${companyId}/${filename}?${urlSearchParam}`;
        const response = await fetch(url);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);
        const objUrl = window.URL.createObjectURL(newBlob);
        link.href = objUrl;

        const tmp = filename?.split('.');
        const downloadFilename = tmp ? `${tmp[1]}.${tmp[2]}` : 'SDS一覧.xlsx';
        link.download = downloadFilename;
        link.click();
      }
    };

    const cancelEdit = async (sdsId: string): Promise<void> => {
      isLoadingSds.value = true;
      isChange.value++;
      await doFetchCompanySds(sdsId);

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...sds.value.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );
      materialFormItems.value.splice(0);
      casFormItems.value.splice(0);
      for (const product of sds.value.sds_products) {
        for (const material of product.sds_section3.material_list) {
          materialFormItems.value.push({
            text: material.material_name,
            value: material.material_name,
          });
          for (const cas of material.cas_list) {
            casFormItems.value.push({
              text: cas.cas_number,
              value: cas.cas_number,
            });
          }
        }
      }
      isChange.value++;
      isLoadingSds.value = false;
    };

    const confirmSave = (event): void => {
      if (isEditing.value) {
        event.preventDefault();
        event.returnValue = '編集中の作業は保存されませんが、よろしいですか？';
      }
    };

    const exportCreateSimple = async (sdsId): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimple(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });
          const exportSds = sdses.value.find((x) => x.uuid === sdsId);
          //for type guard
          if (!exportSds) {
            return;
          }
          const link = document.createElement('a');

          const fileName = removeLastPdfExtension(exportSds.name);
          const now = new Date();

          // 日本のタイムゾーンに変換
          const japanTime = new Intl.DateTimeFormat('ja-JP', {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now);

          // YYYY-MM-DD HH:mm:ss 形式に整形
          const timestampString = japanTime
            .replace(/\//g, '-')
            .replace(/\s/, ' ')
            .replace(/:/g, '');

          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}_${timestampString}_CREATE SIMPLE v2.5.1.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const exportCreateSimpleV3 = async (sdsId): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimpleV3(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });
          const exportSds = sdses.value.find((x) => x.uuid === sdsId);
          //for type guard
          if (!exportSds) {
            return;
          }

          const link = document.createElement('a');

          const fileName = removeLastPdfExtension(exportSds.name);
          const now = new Date();

          // 日本のタイムゾーンに変換
          const japanTime = new Intl.DateTimeFormat('ja-JP', {
            timeZone: 'Asia/Tokyo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(now);

          // YYYY-MM-DD HH:mm:ss 形式に整形
          const timestampString = japanTime
            .replace(/\//g, '-')
            .replace(/\s/, ' ')
            .replace(/:/g, '');

          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}_${timestampString}_CREATE SIMPLE v3.0.2.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const editDynamicListResult = async (
      sdsId: string,
      dynamicListId: number,
      dynamicListResult: SdsDynamicListResult
    ): Promise<void> => {
      try {
        const res = await dispatchPutDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
          dynamicListResult: dynamicListResult,
        });

        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const addDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchPostDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deleteDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchDeleteDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const filterCriteria: Ref<FilterCriteria> = ref({
      statusGroupId: [], // Keep the status filter
      uploadUsers: [], // Add a filter for the upload user
      tags: [], //Add a filter for the tags
      departments: [], // Add a filter for the department
      sdsName: '',
      productName: '',
      revisedAtStartDate: '',
      revisedAtEndDate: '',
      createdAtStartDate: '',
      createdAtEndDate: '',
    });

    const tempfilterCriteria: Ref<FilterCriteria> = ref({
      statusGroupId: [], // Keep the status filter
      uploadUsers: [], // Add a filter for the upload user
      tags: [], //Add a filter for the tags
      departments: [], // Add a filter for the department
      sdsName: '',
      productName: '',
      revisedAtStartDate: '',
      revisedAtEndDate: '',
      createdAtStartDate: '',
      createdAtEndDate: '',
    });

    const hasFilterCriteria = computed(() => {
      return (
        filterCriteria.value.statusGroupId.length ||
        filterCriteria.value.uploadUsers.length ||
        filterCriteria.value.tags.length ||
        filterCriteria.value.departments.length ||
        filterCriteria.value.sdsName ||
        filterCriteria.value.productName ||
        filterCriteria.value.revisedAtStartDate ||
        filterCriteria.value.revisedAtEndDate ||
        filterCriteria.value.createdAtStartDate ||
        filterCriteria.value.createdAtEndDate
      );
    });

    const isFilterPanelVisible = ref(false);

    const toggleFilterPane = (): void => {
      isFilterPanelVisible.value = !isFilterPanelVisible.value;
    };

    const removeStatusGroupId = async (index: string): Promise<void> => {
      filterCriteria.value.statusGroupId =
        filterCriteria.value.statusGroupId.filter((value) => value !== index);
      tempfilterCriteria.value.statusGroupId = [
        ...filterCriteria.value.statusGroupId,
      ];
      applyFilters();
    };

    const removeUploadUserId = async (index: string): Promise<void> => {
      filterCriteria.value.uploadUsers =
        filterCriteria.value.uploadUsers.filter((value) => value !== index);
      tempfilterCriteria.value.uploadUsers = [
        ...filterCriteria.value.uploadUsers,
      ];
      applyFilters();
    };

    const removeTagsId = async (index: number): Promise<void> => {
      filterCriteria.value.tags = filterCriteria.value.tags.filter(
        (value) => value.id !== index
      );
      tempfilterCriteria.value.tags = [...filterCriteria.value.tags];
      applyFilters();
    };

    const removeDepartmentId = async (uuid: string): Promise<void> => {
      filterCriteria.value.departments =
        filterCriteria.value.departments.filter((d) => d.uuid !== uuid);
      tempfilterCriteria.value.departments = [
        ...filterCriteria.value.departments,
      ];
      applyFilters();
    };

    const removeSdsName = async (): Promise<void> => {
      filterCriteria.value.sdsName = '';
      tempfilterCriteria.value.sdsName = '';
      applyFilters();
    };

    const removeProductName = async (): Promise<void> => {
      filterCriteria.value.productName = '';
      tempfilterCriteria.value.productName = '';
      applyFilters();
    };

    const removeRevisedAtStartDate = async (): Promise<void> => {
      filterCriteria.value.revisedAtStartDate = '';
      tempfilterCriteria.value.revisedAtStartDate = '';
      applyFilters();
    };

    const removeRevisedAtEndDate = async (): Promise<void> => {
      filterCriteria.value.revisedAtEndDate = '';
      tempfilterCriteria.value.revisedAtEndDate = '';
      applyFilters();
    };

    const removeCreatedAtStartDate = async (): Promise<void> => {
      filterCriteria.value.createdAtStartDate = '';
      tempfilterCriteria.value.createdAtStartDate = '';
      applyFilters();
    };

    const removeCreatedAtEndDate = async (): Promise<void> => {
      filterCriteria.value.createdAtEndDate = '';
      tempfilterCriteria.value.createdAtEndDate = '';
      applyFilters();
    };

    const applyFilters = async (): Promise<void> => {
      resetSelectedSdses();
      resetPagenation();
      doFetchCompanySdses();
      doFetchCompanySdsesCountWithCondition();
    };

    const resetFilters = async (): Promise<void> => {
      filterCriteria.value.statusGroupId = [];
      filterCriteria.value.uploadUsers = [];
      filterCriteria.value.tags = [];
      filterCriteria.value.departments = [];
      filterCriteria.value.sdsName = '';
      filterCriteria.value.productName = '';
      filterCriteria.value.revisedAtStartDate = '';
      filterCriteria.value.revisedAtEndDate = '';
      filterCriteria.value.createdAtStartDate = '';
      filterCriteria.value.createdAtEndDate = '';
      tempfilterCriteria.value = { ...filterCriteria.value };
      applyFilters();
    };

    const setFilters = async (): Promise<void> => {
      filterCriteria.value = { ...tempfilterCriteria.value };
      applyFilters();
    };

    const cancelFilters = async (): Promise<void> => {
      isFilterPanelVisible.value = false;
    };

    const checkLocalStorageAvailable = (): boolean => {
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        return true;
      } catch (error) {
        return false;
      }
    };

    const updateSelectedSdses = async (
      form: Omit<UpdateCompanySdsesRequestBody, 'sds_uuid_list'>
    ) => {
      const res = await dispatchUpdateCompanySdses(store, {
        companyId: store.state.main.company.uuid,
        data: {
          ...form,
          sds_uuid_list: selectedSdses.value.map((sds) => sds.uuid),
        },
      });
      if (res && res.data) {
        commitAddNotification(store, {
          content: '選択したSDSを更新しました。',
          color: 'success',
        });
      }
      await doFetchCompanySdses();
    };

    const statusLabel = computed(() => {
      return tempfilterCriteria.value.statusGroupId.length === 0
        ? '選択してください'
        : '';
    });
    const uploadUsersLabel = computed(() => {
      return tempfilterCriteria.value.uploadUsers.length === 0
        ? '選択してください'
        : '';
    });
    const tagsLabel = computed(() => {
      return tempfilterCriteria.value.tags.length === 0
        ? '選択してください'
        : '';
    });
    const departmentsLabel = computed(() => {
      return tempfilterCriteria.value.departments.length === 0
        ? '選択してください'
        : '';
    });

    const filteredUsers = computed(() => {
      const usersWithFullName = companyUploadUsers.value.map((user) => ({
        ...user,
        full_name:
          `${user.last_name} ${user.middle_name} ${user.first_name}`.toLowerCase(),
      }));

      if (searchUserText.value === '') {
        return usersWithFullName;
      }

      return usersWithFullName.filter((user) => {
        return user.full_name.includes(searchUserText.value.toLowerCase());
      });
    });

    const filteredTags = computed(() => {
      if (searchTagText.value === '') {
        return tags.value;
      }
      return tags.value.filter((item) => {
        const tag_name = `${item.name}`.toLowerCase();
        return tag_name.includes(searchTagText.value.toLowerCase());
      });
    });

    const filteredDepartments = computed(() => {
      if (searchDepartmentText.value === '') {
        return departments.value;
      }
      return departments.value.filter((item) => {
        const departments_name = `${item.name}`.toLowerCase();
        return departments_name.includes(
          searchDepartmentText.value.toLowerCase()
        );
      });
    });

    onMounted(async (): Promise<void> => {
      if (!checkLocalStorageAvailable())
        console.log('Unable to access LocalStorage.');
      if (checkLocalStorageAvailable()) {
        const savedCriteria = localStorage.getItem('sdsFilterCriteria');
        filterCriteria.value = savedCriteria
          ? JSON.parse(savedCriteria)
          : filterCriteria.value;
      }
      downloadExportData();
      window.onbeforeunload = confirmSave;
      await getCompanyTag();
      await doFetchCompanyUploadUsers();
      await getCompanyDepartments();

      if (
        router.currentRoute.query.uuid &&
        typeof router.currentRoute.query.uuid === 'string'
      ) {
        const uuid = router.currentRoute.query.uuid;
        await doFetchCompanySds(uuid);
        keywordList.value.push(sds.value.name);

        await doFetchCompanySdses();
        await doFetchCompanySdsesCount();
        await doFetchCompanySdsesCountWithCondition();
        fetchDynamicLists();

        currentIndex.value = sdses.value.findIndex((x) => x.uuid === uuid);
        const item = sdses.value.find((x) => x.uuid === uuid);
        if (item) {
          showSdsDetail(item, sdses.value.indexOf(item));
        }
      } else {
        await doFetchCompanySdses();
        await doFetchCompanySdsesCount();
        await doFetchCompanySdsesCountWithCondition();
        fetchDynamicLists();
      }

      watch(
        filterCriteria,
        (newValue) => {
          if (!checkLocalStorageAvailable())
            console.log('Unable to access LocalStorage.');
          if (checkLocalStorageAvailable())
            localStorage.setItem('sdsFilterCriteria', JSON.stringify(newValue));
        },
        { deep: true, immediate: true }
      );
    });

    return {
      sortState,
      toggleSort,
      companyUploadUsers,
      keywordList,
      pdfBlob,
      pdfName,
      limit,
      total,
      checkLocalStorageAvailable,
      page,
      statusList,
      pdfDisplayOptions,
      sds,
      sdses,
      selectedSdses,
      isSelectedAllSdses,
      isSelectedIndeterminateSdses,
      toggleSelectedSds,
      toggleAllSelectedSdses,
      resetSelectedSdses,
      isLoadingSdses,
      isLoadingSds,
      isEditing,
      isTagSelected,
      isUserSelected,
      showSdsDetailView,
      productFormItems,
      materialFormItems,
      casFormItems,
      showPrevSds,
      showNextSds,
      hasPrevSds,
      hasNextSds,
      showSdsDetail,
      doDeleteCompanySdses,
      doUpdateCompanySds,
      cancelEdit,
      resetPagenation,
      doFetchCompanySdses,
      doFetchCompanySdsesCount,
      doFetchCompanySdsesCountWithCondition,
      doFetchCompanySds,
      doFetchPdf,
      downloadExportData,
      exportCreateSimple,
      exportCreateSimpleV3,
      dialogShow,
      cancelExportCreateSimple,
      canExportCreateSimpleCount,
      canExportCreateSimpleCountV3,
      reanalysisSdsModalShow,
      openReanalysisSdsModal,
      closeReanalysisSdsModal,
      reanalysisSds,
      reanalysisSdsComment,
      dynamicLists,
      fetchDynamicLists,
      editDynamicListResult,
      addDynamicListResult,
      deleteDynamicListResult,
      activeSds,
      selectableStatusList,
      statusTextList,
      isFilterPanelVisible,
      filterCriteria,
      tempfilterCriteria,
      toggleFilterPane,
      removeStatusGroupId,
      removeUploadUserId,
      removeTagsId,
      applyFilters,
      resetFilters,
      setFilters,
      cancelFilters,
      getFullName,
      tags,
      departments,
      removeDepartmentId,
      removeSdsName,
      removeProductName,
      removeRevisedAtStartDate,
      removeRevisedAtEndDate,
      removeCreatedAtStartDate,
      removeCreatedAtEndDate,
      hasFilterCriteria,
      isChange,
      updateSelectedSdses,
      hasPermission,
      P,
      isOpenExportDialog,
      openExportDialog,
      closeExportDialog,
      isDialogVisibleRevisedAtStartDate,
      isDialogVisibleRevisedAtEndDate,
      isDialogVisibleCreatedAtStartDate,
      isDialogVisibleCreatedAtEndDate,
      searchUserText,
      searchTagText,
      searchDepartmentText,
      filteredUsers,
      filteredTags,
      filteredDepartments,
      statusLabel,
      uploadUsersLabel,
      tagsLabel,
      departmentsLabel,
      saveDate,
      onClickProductName,
      companyDepartments,
    };
  },
});
